import React from 'react'
import Icon from '../../../shared/components/atoms/Icon/Icon'
import Img from 'gatsby-image'
import { getLastOfUrl } from '../../../shared/state/utils/utilities-arrays'
import useHomeLinkMode from '../../../shared/hooks/useHomeLinkMode'
import './cardArticles.scss'

const CardArticles = props => {
	const { homeLink } = useHomeLinkMode()

	const urlImageDesk = props.articleInfo.imagenArticuloPrevisualizacion[0]
	const urlImageMob = props.articleInfo.imagenArticuloPrevisualizacion[1]

	const titleArt = props.articleInfo.titulo
	const descriptionArt = props.articleInfo.descripcionArticulo

	return (
		<div className='f-card-article-item'>
			<a
				href={
					props.articleInfo.slug != null
						? homeLink +props.articleInfo.slug
						: ''
				}
				className={`f-card-head f-card-article-header f-card-article-header-${getLastOfUrl(
					props.slug
				)}`}
			>
				<Img
					className='f-card-head-img f-card-article-header-img'
					fluid={props.isMobile ? urlImageMob.fluid : urlImageDesk.fluid}
					alt={urlImageDesk.description}
				/>
				{/* <img className={`f-card-head-img f-card-article-header-img`} src={props.isMobile ? urlImageMob.file.url : urlImageDesk.file.url} alt={urlImageDesk.description} /> */}
			</a>
			<div
				className={`f-card-body f-card-body-${getLastOfUrl(
					props.slug
				).toLowerCase()}`}
			>
				<small>
					{getLastOfUrl(props.slug)
						.split('-')
						.join(' ')}
				</small>
				<label>
					<a
						className='f-card-title'
						href={
							props.articleInfo.slug != null
								? homeLink + props.articleInfo.slug
								: ''
						}
					>
						{titleArt}
					</a>
				</label>
				<p className='f-card-description'>
					<a
						href={
							props.articleInfo.slug != null
								? homeLink + props.articleInfo.slug
								: ''
						}
					>
						{descriptionArt}
					</a>
				</p>
				<aside>
					<a
						href={
							props.articleInfo.slug != null
								? homeLink + props.articleInfo.slug
								: ''
						}
						className={`f-card-showmore f-btn-article-${getLastOfUrl(
							props.slug
						).toLowerCase()}`}
					>
						<Icon icon='icon-plus' size='1' tagType='i' />
					</a>
				</aside>
			</div>
			{props.articleInfo?.categorias && props.articleInfo.etiquetas && (
				<div
					className={`f-relational-tag f-relational-tag-${props.articleInfo
						?.categorias[0]?.slug || ''}`}
				>
					{props.articleInfo.etiquetas[0]?.nombreDeEtiqueta || ''}
				</div>
			)}
		</div>
	)
}

export default CardArticles
