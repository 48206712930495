import React from 'react'
import ArticlesSectionPre from '../components/molecules/articlesSectionPre/articlesSectionPre'
import ArticlesSections from '../components/molecules/articlesSections/articlesSections'
import BannerSections from '../components/molecules/bannerSections/bannerSections'
import ProductsSections from '../shared/components/molecules/productsSections/productsSections'
import Layout from '../shared/components/organisms/layout/layout'
import SEO from '../shared/components/seo'
import useBrowserMode from '../shared/hooks/useBrowserMode'
import useMobileMode from '../shared/hooks/useMobileMode'

const HomeSegundoNivel = props => {
	const { isMobile, currentPage } = useMobileMode()
	const { isBrowser } = useBrowserMode()
	const parentNode = isBrowser && document.querySelector('body')
	React.useEffect(() => {
		if (parentNode.scrollTop > 0) {
			parentNode.scroll({
				top: 0,
				behavior: 'smooth',
			})
		}
	})

	const dataFiltered = props.pageContext

	return (
		<Layout title={props.pageContext.metaTitulo} isMobile={isMobile} currentPage={currentPage}>
			<SEO
				title={props.pageContext.metaTitulo}
				description={props.pageContext.metaDescripcion.metaDescripcion}
				image={dataFiltered.banner[0]?.bannerDesktop?.fluid?.src}
				slug={'/' + dataFiltered}
				lang={props.pageContext.language}
			/>
			<article>
				<BannerSections
					isMobile={isMobile}
					bannerInfo={dataFiltered.banner[0]}
					slug={dataFiltered.slug}
				/>
				{props.pageContext.slug === 'cuidadores' ? (
					<>
						<ArticlesSectionPre
							title={
								dataFiltered.descripcionGeneral &&
								dataFiltered.descripcionGeneral.descripcionGeneral
							}
							slug={dataFiltered.slug}
							isMobile={isMobile}
							articlesInfo={dataFiltered.referenciaTarjetasCuidadores}
						/>
					</>
				) : (
					<>
						<ArticlesSections
							title={
								dataFiltered.descripcionGeneral &&
								dataFiltered.descripcionGeneral.descripcionGeneral
							}
							slug={dataFiltered.slug}
							isMobile={isMobile}
							articlesInfo={dataFiltered.articulos}
							ctaUrl={dataFiltered.ctaArticulosRedireccion}
							ctaTitle={
								!isMobile
									? dataFiltered.ctaArticulos
									: dataFiltered.ctaArticulosMobile
							}
						/>
						{dataFiltered.productos && (
							<ProductsSections
								isCurved={true}
								slug={props.pageContext.slug}
								isMobile={isMobile}
								productos={dataFiltered.productos}
							/>
						)}
					</>
				)}
			</article>
		</Layout>
	)
}

export default HomeSegundoNivel
