import React from 'react'
import useBrowserMode from '../../../shared/hooks/useBrowserMode'
import useHomeLinkMode from '../../../shared/hooks/useHomeLinkMode'
import { getLastOfUrl } from '../../../shared/state/utils/utilities-arrays'
import CardArticles from '../cardArticles/cardArticles'
import './articlesSections.scss'

const ArticlesSections = props => {
	const { homeLink } = useHomeLinkMode()
	const { isBrowser } = useBrowserMode()

	return (
		<>
			<h1 className='f-h1 f-articles-title'>{props.title}</h1>
			<div className='f-articles-sections'>
				{props.articlesInfo &&
					props.articlesInfo.map((articleInfo, index) => (
						<CardArticles
							key={index}
							slug={props.slug}
							articleInfo={articleInfo}
							isMobile={props.isMobile}
						/>
					))}
			</div>
			{isBrowser && (
				<a
					className={`f-btn-articles f-btn-articles-${getLastOfUrl(
						props.slug
					)}`}
					href={homeLink + props.ctaUrl}
				>
					{props.ctaTitle}
				</a>
			)}
		</>
	)
}

export default ArticlesSections
