import { axiosInstance } from './axiosInstance'

import * as constants from '../state/constants/apiConstants'

class ProductService {
	static getAnomLikes() {
		return axiosInstance.get(constants.LIKES_PRODUCTS).then(resp => resp.data)
	}

	static setAnomLikes(payload) {
		return axiosInstance
			.post(constants.SET_LIKE, payload)
			.then(resp => resp.data)
	}

	static setAnomUnLikes(payload) {
		return axiosInstance
			.put(constants.SET_UNLIKE, payload)
			.then(resp => resp.data)
	}

	static getAuthLikes(header) {
		return axiosInstance
			.get(constants.LIKES_PRODUCTS, header)
			.then(resp => resp.data)
	}

	static setAuthLikes(payload, header) {
		return axiosInstance
			.post(constants.SET_LIKE, payload, header)
			.then(resp => resp.data)
	}

	static setAuthUnLikes(payload, header) {
		return axiosInstance
			.put(constants.SET_UNLIKE, payload, header)
			.then(resp => resp.data)
	}

	static getQualify(payload) {
		return axiosInstance
			.get(
				constants.QUALIFY_PRODUCT +
					`/${payload.idProduct}/question/category?countryId=${payload.countryId}`
			)
			.then(resp => resp.data)
	}

	static getQualifyDetail(payload) {
		return axiosInstance
			.get(
				constants.QUALIFY_PRODUCT +
					`/${payload.idProduct}/grouped/qualification?countryId=${payload.countryId}`
			)
			.then(resp => resp.data)
	}

	static getComments(payload) {
		return axiosInstance
			.get(
				constants.COMMENTS_PRODUCT +
					`/${payload.idProduct}/comments?limit=${payload.limit}&offset=${payload.page}&countryId=${payload.countryId}`
			)
			.then(resp => resp.data)
	}

	static getAverage(payload, header) {
		return axiosInstance
			.put(constants.SET_UNLIKE, payload, header)
			.then(resp => resp.data)
	}
}

export default ProductService
