import React from 'react'
import CardArticlesPre from '../../../shared/components/molecules/cardArticlesPre/cardArticlesPre'
import './articlesSectionPre.scss'

const ArticlesSectionPre = props => {
	return (
		<>
			<h1 className='f-h1 f-articles-title'>{props.title}</h1>
			<div className='f-card-pre-cont'>
				{props.articlesInfo &&
					props.articlesInfo.map((articleInfo, index) => (
						<CardArticlesPre
							key={index}
							slug={articleInfo.slug}
							articleInfo={articleInfo}
							isMobile={props.isMobile}
						/>
					))}
			</div>
		</>
	)
}

export default ArticlesSectionPre
