import React from 'react'
import Img from 'gatsby-image'
import { getLastOfUrl } from '../../../shared/state/utils/utilities-arrays'
import './bannerSections.scss'
import SessionService from '../../../shared/services/session'
import { convertText } from '../../../shared/utils/convertFirstCharacterToUppercase'

const BannerSections = props => {
	const imgDesktop = props.bannerInfo.bannerDesktop
	const imgMobile = props.bannerInfo.bannerMobile
	const homeLink =
		typeof window !== 'undefined' ? '//' + window.location.host + '/' : '/'

	const noButton = () => {
		return SessionService.getUserData() || !props.bannerInfo.tieneBoton
	}

	const captureHref = (e, url) => {
		e.preventDefault()
		if (url !== null && url !== '') {
			if (url.includes('http')) {
				window.open(url, '_blank')
			} else {
				window.location.href = homeLink + url
			}
		}
	}

	return (
		<div
			className={`f-banner-sections f-banner-sections-${getLastOfUrl(
				props.slug
			)}`}
		>
			<div
				className={`f-description-wrapper f-description-wrapper-${props.bannerInfo.localizacionTexto}`}
			>
				<h2
					className={`f-banner-title f-text-color-${props.bannerInfo.colorTexto}`}
				>
					{props.bannerInfo.nombre}
				</h2>
				<p
					className={`f-banner-description f-text-color-${props.bannerInfo.colorTexto}`}
				>
					{props.bannerInfo.descripcionImagen}
				</p>
				{noButton()
					? null
					: props.bannerInfo.cta && (
							<a
								href={homeLink + props.bannerInfo.enlace}
								onClick={e => captureHref(e, props.bannerInfo.enlace)}
							>
								<button
									className={`f-btn-promo f-btn-promo-${getLastOfUrl(
										props.slug
									)}`}
								>
									{convertText(props.bannerInfo.cta)}
								</button>
							</a>
					  )}
			</div>
			<Img
				className='f-img-banner'
				fluid={props.isMobile ? imgMobile.fluid : imgDesktop.fluid}
				alt={imgDesktop.description}
			/>
			<div className='f-button-group'>
				<a
					href={'/mujeres'}
					className={
						props.slug === 'mujeres'
							? 'f-btn-section f-btn-mujeres f-btn-mujeres-active'
							: 'f-btn-section f-btn-mujeres'
					}
				>
					MUJERES
				</a>
				<a
					href={'/hombres'}
					className={
						props.slug === 'hombres'
							? 'f-btn-section f-btn-hombres f-btn-hombres-active'
							: 'f-btn-section f-btn-hombres'
					}
				>
					HOMBRES
				</a>
				<a
					href={'/cuidadores'}
					className={
						props.slug === 'cuidadores' ||
						getLastOfUrl(props.slug) === 'eres-un-profesional' ||
						getLastOfUrl(props.slug) === 'cuidas-a-alguien' ||
						getLastOfUrl(props.slug) === 'institucion'
							? 'f-btn-section f-btn-cuidadores f-btn-cuidadores-active'
							: 'f-btn-section f-btn-cuidadores'
					}
				>
					CUIDADORES
				</a>
			</div>
		</div>
	)
}

export default BannerSections
