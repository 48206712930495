import Img from 'gatsby-image'
import React from 'react'
import { getLastOfUrl } from '../../../state/utils/utilities-arrays'
import './cardArticlesPre.scss'
import useHomeLinkMode from '../../../hooks/useHomeLinkMode'

const CardArticlesPre = props => {
	const { homeLink } = useHomeLinkMode()

	const urlImageDesk = props.articleInfo.imagenTarjeta[0]
	const urlImageMob = props.articleInfo.imagenTarjeta[1]

	const titleArt = props.articleInfo.tituloTarjeta
	const descriptionArt = props.articleInfo.descripcionTarjeta

	return (
		<div className='f-card-pre-article-item'>
			<a href={homeLink + props.articleInfo.slug} className={'f-card-head'}>
				<div
					className={`f-card-body-${getLastOfUrl(props.slug).toLowerCase()}`}
				>
					<label className='f-card-pre-title'>{titleArt}</label>
				</div>
				<div
					className={`f-card-article-header f-card-article-header-${getLastOfUrl(
						props.slug
					)}`}
				>
					<Img
						className='f-card-head-img f-card-article-header-img'
						fluid={props.isMobile ? urlImageMob.fluid : urlImageDesk.fluid}
						alt={urlImageDesk.description}
					/>
				</div>
				<div
					className={`f-card-body f-card-body-${getLastOfUrl(
						props.slug
					).toLowerCase()}`}
				>
					<p className='f-card-description'>{descriptionArt}</p>
				</div>
			</a>
		</div>
	)
}

export default CardArticlesPre
